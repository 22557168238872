import React, {useState} from 'react';
import Sidebar from '../../parts/sidebar';
import { MDBTable, MDBTableHead, MDBTableBody,MDBSpinner } from 'mdb-react-ui-kit';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBInput,
  MDBBtn
} from 'mdb-react-ui-kit';
import { useEffect } from 'react';
import axios from 'axios';

const Requests = () => {
  const [showModal, setShowModal] = useState(false);
  const [file,setFile]=useState(null);
  const [submit,setSubmit]=useState(false);
  const [reqId,setReqId]=useState("");
  const [requests, setRequests] = useState([]);
  const [backup,setBackup]=useState([]);

  const [reqtype,setReqtype]=useState("");

  const toggleModal = () => setShowModal(!showModal);


  useEffect(()=>{
    getRequests();
  },[])

  async function getRequests() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Admin/getRequests`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        }
      );

      const data = await response.json();
      setRequests(data.data);
      setBackup(data.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData();
    formData.append('reqId', reqId);
    formData.append('file', file);

    try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/requestSolution`, formData, {
        headers: {
        'Content-Type': 'multipart/form-data',
        "api-key": process.env.REACT_APP_API_KEY,
        },
    });
    const data = response.data;
    if(data.message=="added"){
        form.reset();
        setSubmit(false);
        setShowModal(false);
        getRequests();
    }
    } catch (error) {
    console.error('Error:', error.message);
    setSubmit(false);
    }
  };

  const handleClose = async (id) => {
    if(window.confirm("Are you sure you want to close?")){
      const formData = new FormData();
      formData.append('reqId', id);

      try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/CloseRequest`, formData, {
          headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
          },
      });
      const data = response.data;
      if(data.message=="added"){
          getRequests();
      }
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };

  const handleProcessing=async (id)=>{
      try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/Processing?reqId=${id}`, {
          headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
          },
      });
      const data = response.data;
      if(data.message=="added"){
          getRequests();
      }
      } catch (error) {
        console.error('Error:', error.message);
      }
  }

  const [sortDirection, setSortDirection] = useState('asc');

  const sortByRequestId = () => {
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));

    setRequests((prevRequests) => [
      ...prevRequests.sort((a, b) => {
        const sortFactor = sortDirection === 'asc' ? 1 : -1;
        return sortFactor * (a.Id - b.Id);
      }),
    ]);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>Requests</h3>
        <select class="form-select" aria-label="Default select example" value={reqtype} onChange={(e)=>{
          setReqtype(e.target.value);
          if(e.target.value=="All"){
            setRequests(backup);
          }
          else{
            setRequests(backup.filter((item)=>item.status==e.target.value));
          }
          }} style={{width:"400px",marginLeft:"2%"}}>
          <option selected>Filter Requests</option>
          <option value="Open">Open</option>
          <option value="Closed">Closed</option>
          <option value="All">All</option>
        </select>
        <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col' style={{ cursor: 'pointer', fontSize: '12px' }} onClick={sortByRequestId}>
                Req# {sortDirection === 'asc' ? '▲' : '▼'}
              </th>
              <th scope='col'>Username</th>
              <th scope='col'>Make</th>
              <th scope='col'>Controller</th>
              <th scope='col'>Solution Type</th>
              <th scope='col'>Comments</th>
              <th scope='col'>Dated</th>
              <th scope='col'>Download</th>
              <th scope='col'>Status</th>
              <th scope='col'>Solution</th>
              <th scope='col'>Not Possible</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {requests.map((item,index)=>(
              <tr>
              <td>
                <p className='fw-normal mb-1'>{item.Id}</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>{item.firstname+' '+item.lastname}</p>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='ms-3'>
                    <p className='fw-bold mb-1'>{item.make}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className='fw-normal mb-1'>{item.ecu}</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>{item.solutionType}</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>{item.comments}</p>
              </td>
              <td>
                <p className='text-muted mb-0'>{new Date(item.dated).toLocaleString()}</p>
              </td>
              <td>
                {item.file=="Not Possible"?(
                  "No Solution"
                ):item.status=="Closed"?("Closed"):(
                  <p className='text-muted mb-0' onClick={()=>{handleProcessing(item.Id)}}><a href={`${process.env.REACT_APP_BASE_URL}/files/${item.file}`}>File</a></p>
                )}
              </td>
              <td>
                <p className='mb-0' style={{color:item.status=="Closed"?"red":item.status=="Open"?"green":item.status=="Pending"?"orange":""}}>{item.status}</p>
              </td>
              <td>
                {item.status=="Closed" && item.solution=="Not Possible"?(
                  <p className='text-muted mb-0'>Closed</p>
                ):item.status=="Closed"?(
                  <p className='text-muted mb-0'><a href={`${process.env.REACT_APP_BASE_URL}/files/${item.solution}`}>File</a></p>
                ):(
                  <p className='text-muted mb-0'><i className='fa fa-plus' style={{cursor:"pointer"}} onClick={()=>{
                    toggleModal();
                    setReqId(item.Id);
                  }}></i></p>
                )}
              </td>
              <td>
                {item.solution=="Not Possible"?(
                  "Not Possible"
                ):item.solution!=null?(
                  "-"
                ):(
                  <MDBBtn onClick={()=>{handleClose(item.Id)}}>Close</MDBBtn>
                )}
              </td>
            </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>

      <MDBModal show={showModal} onHide={() => setShowModal(false)} tabIndex='-1'>
        <MDBModalDialog centered size="md">
            <MDBModalContent style={{ color: 'black' }}>
            <MDBModalBody>
            <center><h3 style={{color:"black",fontFamily:"bahnschrift",marginTop:"15px"}}>Solution File</h3></center>
                
            <center>
                <div>
                    <p style={{marginTop:"30px"}}>Add Solution File</p>
                    <form onSubmit={handleSubmit}>
                      <MDBInput type='file' id="file" name='file'  onChange={(event) => setFile(event.target.files[0])}/>
                      <MDBBtn style={{marginTop:"8px",width:"100%",color:"white",backgroundColor:"black"}}>
                        {submit?(
                          <MDBSpinner></MDBSpinner>
                        ):(
                          <span>Submit</span>
                        )}
                      </MDBBtn>
                    </form>
                </div>
            </center>
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>
    </div>
  );
};

export default Requests;
