import React, {useState} from 'react';
import { MDBInput,MDBRow,MDBCol } from 'mdb-react-ui-kit';
import Sidebar from '../../parts/sidebar';
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

const History = () => {
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>Scripts History</h3>
        <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col'>Solution Name</th>
              <th scope='col'>Car Type</th>
              <th scope='col'>Controller</th>
              <th scope='col'>Script Type</th>
              <th scope='col'>Original Name</th>
              <th scope='col'>Dated</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='ms-3'>
                    <p className='fw-bold mb-1'>EGR OFF</p>
                  </div>
                </div>
              </td>
              <td>
                <p className='fw-normal mb-1'>VAG</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>EC17CP54</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>SearchAndReplace</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>Script Name</p>
              </td>
              <td>
                <p className='text-muted mb-0'>2021-10-03 2:34:20</p>
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='ms-3'>
                    <p className='fw-bold mb-1'>EGR OFF</p>
                  </div>
                </div>
              </td>
              <td>
                <p className='fw-normal mb-1'>VAG</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>EC17CP54</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>SearchAndReplace</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>Script Name</p>
              </td>
              <td>
                <p className='text-muted mb-0'>2021-10-03 2:34:20</p>
              </td>
            </tr>
            <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='ms-3'>
                    <p className='fw-bold mb-1'>EGR OFF</p>
                  </div>
                </div>
              </td>
              <td>
                <p className='fw-normal mb-1'>VAG</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>EC17CP54</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>SearchAndReplace</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>Script Name</p>
              </td>
              <td>
                <p className='text-muted mb-0'>2021-10-03 2:34:20</p>
              </td>
            </tr>    
          </MDBTableBody>
        </MDBTable>
      </div>
    </div>
  );
};

export default History;
