import React, {useEffect, useState} from 'react';
import Sidebar from '../../parts/sidebar';
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import axios from 'axios';
import { MDBSpinner } from 'mdb-react-ui-kit';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalBody,
} from 'mdb-react-ui-kit';
import { MDBInputGroup, MDBInput,MDBCheckbox } from 'mdb-react-ui-kit';

const Timing = () => {
  const [MondayTo,setMondayTo]=useState(""); 
  const [MondayFrom,setMondayFrom]=useState(""); 
  const [TuesdayTo,setTuesdayTo]=useState(""); 
  const [TuesdayFrom,setTuesdayFrom]=useState(""); 
  const [WednesdayTo,setWednesdayTo]=useState(""); 
  const [WednesdayFrom,setWednesdayFrom]=useState(""); 
  const [ThursdayTo,setThursdayTo]=useState(""); 
  const [ThursdayFrom,setThursdayFrom]=useState(""); 
  const [FridayTo,setFridayTo]=useState(""); 
  const [FridayFrom,setFridayFrom]=useState(""); 
  const [SaturdayTo,setSaturdayTo]=useState(""); 
  const [SaturdayFrom,setSaturdayFrom]=useState(""); 

  const [admins,setAdmins]=useState([]);
  const [submit,setSubmit]=useState(false);
  const [email,setEmail]=useState("");
  const [password,setPassword]=useState("");
  const [name,setName]=useState("");
  const [file,setFile]=useState(null);
  const [id,setId]=useState("");
  
  useEffect(()=>{
    getTiming();
  },[])

  const getTiming = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getTiming`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
  
      const responseData = response.data.data;
  
      responseData.forEach(dayTiming => {
        const { day, toTime, fromTime } = dayTiming;

        switch (day) {
          case 'Monday':
            setMondayTo(toTime || "");
            setMondayFrom(fromTime || "");
            break;
          case 'Tuesday':
            setTuesdayTo(toTime || "");
            setTuesdayFrom(fromTime || "");
            break;
          case 'Wednesday':
            setWednesdayTo(toTime || "");
            setWednesdayFrom(fromTime || "");
            break;
          case 'Thursday':
            setThursdayTo(toTime || "");
            setThursdayFrom(fromTime || "");
            break;
          case 'Friday':
            setFridayTo(toTime || "");
            setFridayFrom(fromTime || "");
            break;
          case 'Saturday':
            setSaturdayTo(toTime || "");
            setSaturdayFrom(fromTime || "");
            break;
          default:
            break;
        }
      });
      
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData();
    formData.append('mondayto', MondayTo);
    formData.append('mondayfrom', MondayFrom);
    formData.append('tuesdayto', TuesdayTo);
    formData.append('tuesdayfrom', TuesdayFrom);
    formData.append('wednesdayto', WednesdayTo);
    formData.append('wednesdayfrom', WednesdayFrom);
    formData.append('thursdayto', ThursdayTo);
    formData.append('thursdayfrom', ThursdayFrom);
    formData.append('fridayto', FridayTo);
    formData.append('fridayfrom', FridayFrom);
    formData.append('saturdayto', SaturdayTo);
    formData.append('saturdayfrom', SaturdayFrom);

    try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/updateTiming`, formData, {
        headers: {
        'Content-Type': 'application/json',
        "api-key": process.env.REACT_APP_API_KEY,
        },
    });
    const data = response.data;
    if(data.message=="added"){
        setSubmit(false);
        showSuccessMessage();
    }
    } catch (error) {
        console.error('Error:', error.message);
        setSubmit(false);
    }
  };

  const showSuccessMessage = () => {
    toast.success('Open Timing Updated', {
      position: 'top-center',
    });
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ToastContainer />
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>Open Timing</h3>
        <div class="container bootdey">
          <Row>
            <Col xs={12} md={6} lg={7}>
              <form
                style={{
                  textAlign: "left",
                  marginLeft: "30px",
                }}
                onSubmit={handleSubmit}
                id="emailsender"
                method="post"
              >
                <Card className="mx-auto mt-5" style={{ maxWidth: "90%" }}>
                  <Card.Body>
                    <style>{`
                  @media (max-width: 576px) {
                    .card {
                      width: 90% !important;
                      max-width: 90% !important;
                    }
                  }
                `}</style>
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label style={{ color: "black" }}>Monday</Form.Label>
                        <MDBInputGroup>
                        <MDBInput
                            placeholder="Start Time"
                            type="time"
                            name="startTime"
                            id="startTime"
                            required
                            value={MondayTo}
                            onChange={(e)=>{setMondayTo(e.target.value)}}
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                        <div className="input-group-append">
                        <span className="input-group-text">-</span>
                        </div>
                        <MDBInput
                            placeholder="End Time"
                            type="time"
                            name="endTime"
                            id="endTime"
                            value={MondayFrom}
                            onChange={(e)=>{setMondayFrom(e.target.value)}}
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                    </MDBInputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label style={{ color: "black" }}>Tuesday</Form.Label>
                      <MDBInputGroup>
                        <MDBInput
                            placeholder="Start Time"
                            type="time"
                            name="startTime"
                            value={TuesdayTo}
                            onChange={(e)=>{setTuesdayTo(e.target.value)}}
                            id="startTime"
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                        <div className="input-group-append">
                        <span className="input-group-text">-</span>
                        </div>
                        <MDBInput
                            placeholder="End Time"
                            type="time"
                            value={TuesdayFrom}
                            onChange={(e)=>{setTuesdayFrom(e.target.value)}}
                            name="endTime"
                            id="endTime"
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                    </MDBInputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label style={{ color: "black" }}>
                        Wednesday
                      </Form.Label>
                      <MDBInputGroup>
                        <MDBInput
                            placeholder="Start Time"
                            type="time"
                            name="startTime"
                            id="startTime"
                            value={WednesdayTo}
                            onChange={(e)=>{setWednesdayTo(e.target.value)}}
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                        <div className="input-group-append">
                        <span className="input-group-text">-</span>
                        </div>
                        <MDBInput
                            placeholder="End Time"
                            type="time"
                            name="endTime"
                            value={WednesdayFrom}
                            onChange={(e)=>{setWednesdayFrom(e.target.value)}}
                            id="endTime"
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                    </MDBInputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label style={{ color: "black" }}>
                        Thursday
                      </Form.Label>
                      <MDBInputGroup>
                        <MDBInput
                            placeholder="Start Time"
                            type="time"
                            value={ThursdayTo}
                            onChange={(e)=>{setThursdayTo(e.target.value)}}
                            name="startTime"
                            id="startTime"
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                        <div className="input-group-append">
                        <span className="input-group-text">-</span>
                        </div>
                        <MDBInput
                            placeholder="End Time"
                            type="time"
                            value={ThursdayFrom}
                            onChange={(e)=>{setThursdayFrom(e.target.value)}}
                            name="endTime"
                            id="endTime"
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                    </MDBInputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label style={{ color: "black" }}>
                        Friday
                      </Form.Label>
                      <MDBInputGroup>
                        <MDBInput
                            placeholder="Start Time"
                            type="time"
                            value={FridayTo}
                            onChange={(e)=>{setFridayTo(e.target.value)}}
                            name="startTime"
                            id="startTime"
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                        <div className="input-group-append">
                        <span className="input-group-text">-</span>
                        </div>
                        <MDBInput
                            placeholder="End Time"
                            type="time"
                            value={FridayFrom}
                            onChange={(e)=>{setFridayFrom(e.target.value)}}
                            name="endTime"
                            id="endTime"
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                    </MDBInputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label style={{ color: "black" }}>
                        Saturday
                      </Form.Label>
                      <MDBInputGroup>
                        <MDBInput
                            placeholder="Start Time"
                            type="time"
                            value={SaturdayTo}
                            onChange={(e)=>{setSaturdayTo(e.target.value)}}
                            name="startTime"
                            id="startTime"
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                        <div className="input-group-append">
                        <span className="input-group-text">-</span>
                        </div>
                        <MDBInput
                            placeholder="End Time"
                            type="time"
                            value={SaturdayFrom}
                            onChange={(e)=>{setSaturdayFrom(e.target.value)}}
                            name="endTime"
                            id="endTime"
                            required
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                    </MDBInputGroup>
                    </Form.Group>

                   
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "black",
                        color:"white",
                        border: "none",
                        fontWeight: "bold",
                        width: "100%", 
                      }}
                      block 
                    >
                      {submit?(
                        <MDBSpinner></MDBSpinner>
                      ):(
                        <span>Update Timing</span>
                      )}
                    </Button>
                  </Card.Body>
                </Card>
              </form>
            </Col>
          </Row>
      </div>
      </div>
    </div>
  );
};

export default Timing;
