import React, {useEffect, useState} from 'react';
import Sidebar from '../../parts/sidebar';
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import axios from 'axios';
import { MDBSpinner } from 'mdb-react-ui-kit';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Email = () => {
  const [dealers,setDealers]=useState([]);
  const [submit,setSubmit]=useState(false);
  const [email,setEmail]=useState("");
  const [subject,setSubject]=useState("");
  const [body,setBody]=useState("");

  useEffect(()=>{
    getDealers();
  },[])

  const getDealers = async (e) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/getDealers`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setDealers(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('body', body);

    try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/sendMail`, formData, {
        headers: {
        'Content-Type': 'application/json',
        "api-key": process.env.REACT_APP_API_KEY,
        },
    });
    const data = response.data;
    if(data.message=="sent"){
        form.reset();
        setSubmit(false);
        showSuccessMessage();
    }
    } catch (error) {
    console.error('Error:', error.message);
    setSubmit(false);
    }
  };

  const showSuccessMessage = () => {
    toast.success('Email Sent', {
      position: 'top-center',
    });
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ToastContainer />
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>Email</h3>
        <div class="container bootdey">
          <Row>
            <Col xs={12} md={6} lg={7}>
              <form
                style={{
                  textAlign: "left",
                  marginLeft: "30px",
                }}
                onSubmit={handleSubmit}
                id="emailsender"
                method="post"
              >
                <Card className="mx-auto mt-5" style={{ maxWidth: "90%" }}>
                  <Card.Body>
                    <style>{`
                  @media (max-width: 576px) {
                    .card {
                      width: 90% !important;
                      max-width: 90% !important;
                    }
                  }
                `}</style>
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label style={{ color: "black" }}>Email</Form.Label>
                      <Form.Control
                        placeholder="Enter Email"
                        type="email"
                        name="to"
                        id="email"
                        onChange={(e)=>{setEmail(e.target.value)}}
                        style={{ backgroundColor: "#F9F9F9" }}
                      />
                      <span id="to-error"></span>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label style={{ color: "black" }}>
                        Subject
                      </Form.Label>
                      <Form.Control
                        id="subject"
                        name="subject"
                        type="text"
                        onChange={(e)=>{setSubject(e.target.value)}}
                        placeholder="Enter Subject"
                        style={{ backgroundColor: "#F9F9F9" }}
                      />
                      <span id="subject-error"></span>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formSubject">
                      <Form.Label style={{ color: "black" }}>
                        Message
                      </Form.Label>
                      <textarea
                        class="form-control"
                        id="message"
                        name="message"
                        rows="5"
                        onChange={(e)=>{setBody(e.target.value)}}
                        placeholder="Your Message"
                        style={{ backgroundColor: "#F9F9F9" }}
                      ></textarea>
                      <span id="message-error"></span>
                    </Form.Group>
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "black",
                        color:"white",
                        border: "none",
                        fontWeight: "bold",
                        width: "100%", 
                      }}
                      block 
                    >
                      {submit?(
                        <MDBSpinner></MDBSpinner>
                      ):(
                        <span>Send</span>
                      )}
                    </Button>
                  </Card.Body>
                </Card>
              </form>
            </Col>

            <Col
              xs={12}
              md={6}
              lg={4}
              className="emailcard"
              style={{ marginLeft: "10px" }}
            >
              <Card className="mx-auto mt-5">
                <Card.Body>
                  {dealers.map((item,index)=>(
                    <div>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ marginBottom: "10px" }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            "https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png"
                          }
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "50px",
                            marginLeft: "-15px",
                          }}
                          alt=""
                        />
                        <div>
                          <a
                            href="#"
                            onClick={() => {
                              document.getElementById("email").value = item.email;
                              setEmail(item.email);
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              style={{
                                fontSize: "17px",
                                fontWeight: "bold",
                                fontFamily: "Bahnschrift",
                                color: "#100F3D",
                                textAlign: "left",
                              }}
                            >
                             {item.firstname}
                            </div>
                          </a>
                          <div
                            style={{
                              fontFamily: "Bahnschrift",
                              fontSize: "13px",
                              textAlign: "left",
                            }}
                          >
                            {item.email}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#DFE3EF",
                        marginBottom: "10px",
                      }}
                    ></div>
                  </div>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          </Row>
      </div>
      </div>
    </div>
  );
};

export default Email;
