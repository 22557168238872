import React, { useEffect, useState } from 'react';
import Sidebar from '../../parts/sidebar';
import { MDBRow,MDBCol, MDBInput,MDBSpinner } from 'mdb-react-ui-kit';
import { MDBBtn } from 'mdb-react-ui-kit';
import axios from 'axios';

const Pricing = () => {
  const [pricewt,setProcewt]=useState(0);
  const [pricewot,setPricewot]=useState(0);
  const [submit,setSubmit]=useState(false);
  const [priceSubmit,setPriceSubmit]=useState(false);
  const [precre,setPrecre]=useState();
  const [prestage,setPrestage]=useState();
  const [norCre,setNorcre]=useState();
  const [norStage,setNorstage]=useState();
  const [cusCre,setCustcre]=useState();
  const [cusStage,setCustomStage]=useState();

  useEffect(()=>{
    getPricing();
    getPlans();
  },[])

  const getPricing = async (e) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getPricing`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      console.log(responseData);
      setProcewt(responseData.data[0].pricewt);
      setPricewot(responseData.data[0].pricewot);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  
  const getPlans = async (e) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getPlans`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setPrecre(responseData.data[0].credits);
      setPrestage(responseData.data[0].stage);
      setNorcre(responseData.data[1].credits);
      setNorstage(responseData.data[1].stage);
      setCustcre(responseData.data[2].credits);
      setCustomStage(responseData.data[2].stage);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmit(true);
    const data={
        wtax:pricewt,
        wotax:pricewot,
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/updatePricing`,data,{
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      if(responseData.message=="updated"){
        setSubmit(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handlePricingSubmit = async (event) => {
    event.preventDefault();
    setPriceSubmit(true);
    const data={
        preCre:precre,
        preStage:prestage,
        norCre:norCre,
        norStage:norStage,
        cusCre:cusCre,
        cusStage:cusStage,
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/updatePlan`,data,{
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      if(responseData.message=="updated"){
        setPriceSubmit(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setPriceSubmit(false);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>Pricing</h3>
          <form onSubmit={handlePricingSubmit}>
            <MDBRow style={{margin:"30px"}}>
              <MDBCol md={4}>
                  <h4 style={{color:"black"}}>Premium User</h4>
              </MDBCol>
              <MDBCol md={4}>
                  <MDBInput label='Credits' id='credits' type='text' value={precre} onChange={(e)=>{setPrecre(e.target.value)}}/>
              </MDBCol>
              <MDBCol md={4}>
                  <MDBInput label='Stage' id='stage' type='text' value={prestage} onChange={(e)=>{setPrestage(e.target.value)}}/>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{margin:"30px"}}>
              <MDBCol md={4}>
                  <h4 style={{color:"black"}}>Normal User</h4>
              </MDBCol>
              <MDBCol md={4}>
                  <MDBInput label='Credits' id='credits' type='text' value={norCre} onChange={(e)=>{setNorcre(e.target.value)}}/>
              </MDBCol>
              <MDBCol md={4}>
                  <MDBInput label='Stage' id='stage' type='text' value={norStage} onChange={(e)=>{setNorstage(e.target.value)}}/>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{margin:"30px"}}>
              <MDBCol md={4}>
                  <h4 style={{color:"black"}}>Custom User</h4>
              </MDBCol>
              <MDBCol md={4}>
                  <MDBInput label='Credits' id='credits' type='text' value={cusCre} onChange={(e)=>{setCustcre(e.target.value)}}/>
              </MDBCol>
              <MDBCol md={4}>
                  <MDBInput label='Stage' id='stage' type='text' value={cusStage} onChange={(e)=>{setCustomStage(e.target.value)}}/>
              <MDBBtn style={{marginTop:"10px"}}>{
                priceSubmit ? (
                  <MDBSpinner></MDBSpinner>
                ):(
                  <span>Update</span>
                )
                }</MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
          <form onSubmit={handleSubmit}>
            <MDBRow style={{margin:"30px"}}>
              <MDBCol md={4}>
                <p style={{fontWeight:"bold",color:"black",fontSize:"22px"}}>Price of Credit With Tax</p>
                <p style={{marginTop:"-10px"}}>Current Price of Credit with tax: {pricewt} EURO</p>
              </MDBCol>
              <MDBCol md={4}>
                <MDBInput label='Price' id='form1' type='text' value={pricewt} onChange={(e)=>{setProcewt(e.target.value)}} required/>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{margin:"30px"}}>
              <MDBCol md={4}>
                <p style={{fontWeight:"bold",color:"black",fontSize:"22px"}}>Price of Credit Without Tax</p>
                <p style={{marginTop:"-10px"}}>Current Price of Credit without tax: {pricewot} EURO</p>
              </MDBCol>
              <MDBCol md={4} style={{marginTop:"-15px"}}>
                <MDBInput label='Price' id='form1' type='text' value={pricewot} onChange={(e)=>{setPricewot(e.target.value)}} required/>
                <MDBBtn style={{marginTop:"10px"}}>{
                  submit ? (
                    <MDBSpinner></MDBSpinner>
                  ):(
                    <span>Update</span>
                  )
                }</MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
      </div>
    </div>
  );
};

export default Pricing;
