import React, {useEffect, useState} from 'react';
import Sidebar from '../../parts/sidebar';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import axios from 'axios';

const Scripts = () => {
  const [scripts,getScripts]=useState([]);

  useEffect(()=>{
    getDetails();
  },[])

  const getDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getScripts`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;

      getScripts(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  const deleteScript = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/Admin/deleteScript?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      if(responseData.message=="deleted"){
        getDetails();
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
};

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>Scripts ({scripts.length})</h3>
        <div style={{ height: "550px", overflowY: "auto", overflowX: "auto", maxWidth: "100%" }}>
        <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col'>Car</th>
              <th scope='col'>Controller</th>
              <th scope='col'>Script File</th>
              <th scope='col'>Script Fiename</th>
              <th scope='col'>Credits</th>
              <th scope='col'>Dated</th>
              <th scope='col'>Delete</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {scripts.map((item,index)=>(
              <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='ms-3'>
                    <p className='fw-bold mb-1'>{item.car}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className='fw-normal mb-1'>{item.controller}</p>
              </td>
              <td>
                <p className='fw-normal mb-1'><a href={`${process.env.REACT_APP_BASE_URL}/scripts/${item.car}/${item.controller}/${item.scriptfile}`}>Script</a></p>
              </td>
              <td>
                <p className='fw-normal mb-1' title={item.scriptfile}>
                  {item.scriptfile.substring(0, 20)}
                </p>
              </td>
              <td>
                <p className='fw-normal mb-1'>{item.credits}</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>{new Date(item.dated).toLocaleString()}</p>
              </td>
              <td>
                <p className='fw-normal mb-1'>
                  <i className='fa fa-trash' style={{cursor:"pointer"}} onClick={()=>{
                    if(window.confirm("Are you sure you want to Delete?")){
                      deleteScript(item.Id);
                    }
                  }}></i>
                </p>
              </td>
            </tr>   
            ))} 
          </MDBTableBody>
        </MDBTable>
        </div>
      </div>
    </div>
  );
};

export default Scripts;
