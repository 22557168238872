import './App.css';
import {Switch,Route} from 'react-router-dom';
import Login from './Components/login';
import Dashboard from './Components/Pages/dashboard';
import Users from './Components/Pages/Users/users';
import Subscriptions from './Components/Pages/Users/subscriptions';
import Usershistory from './Components/Pages/Users/history';
import Usersinfo from './Components/Pages/Users/usersinfo';
import ECU from './Components/Pages/ECU/ecu';
import Scripts from './Components/Pages/ECU/scripts';
import History from './Components/Pages/ECU/history';
import Requests from './Components/Pages/Requests/requests';
import Tickets from './Components/Pages/Requests/tickets';
import Chat from './Components/Pages/Requests/chat';
import Email from './Components/Pages/Requests/email';
import Status from './Components/Pages/Requests/status';
import Pricing from './Components/Pages/ECU/pricing';
import PrivateRoute from './Components/private';
import UserDetails from './Components/Pages/Users/userdetails';
import Solutions from './Components/Pages/ECU/solution';
import Admins from './Components/Pages/Admins/addadmins';
import PrivateAdmin from './Components/privateadmin';
import Timing from './Components/Pages/Admins/timing';
import Error from './Components/404';

function App() {
  return (
    <div className="app">
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route exact path="/404" component={Error}/>
        <Route exact path="/dashboard" component={Dashboard}/>
        <Route exact path="/manage-users" component={Users}/>
        <Route exact path="/subscriptions" component={Subscriptions}/>
        <Route exact path="/history" component={Usershistory}/>
        <Route exact path="/users-info" component={Usersinfo}/>
        <Route exact path="/ecu" component={ECU}/>
        <Route exact path="/scripts" component={Scripts}/>
        <Route exact path="/scripts-history" component={History}/>
        <Route exact path="/requests" component={Requests}/>
        <Route exact path="/tickets" component={Tickets}/>
        <Route exact path="/chat" component={Chat}/>
        <Route exact path="/email" component={Email}/>
        <Route exact path="/status" component={Status}/>
        <Route exact path="/pricing" component={Pricing}/>
        <Route exact path="/user-details/:id" component={UserDetails}/>
        <Route exact path="/manage-admins" component={Admins}/>
        <Route exact path="/solutions" component={Solutions}/>
        <Route exact path="/timing" component={Timing}/>
      </Switch>
    </div>
  );
}

export default App;
