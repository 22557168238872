import React from 'react';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBSpinner,MDBCard,MDBCardBody } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";

const colStyle = {
  height: '100%',
  overflow: 'hidden',
  padding: '0',
  position: 'relative',
};

const textStyle = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  padding: '20px',
  boxSizing: 'border-box',
  color: '#fff',
};

export default function Login() {
  const [submit,setSubmit]=useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const data={
      email:document.getElementById("email").value,
      password:document.getElementById("password").value
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/login`, data, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      if(responseData.message=="success"){
        Cookies.set('email', responseData.email, { expires: 1 });
        Cookies.set('adtoken', responseData.token, { expires: 1 });
        Cookies.set('adminEmail', responseData.adminEmail, { expires: 1 });
        Cookies.set('adminId', responseData.adminId, { expires: 1 });
        Cookies.set('adminName', responseData.adminName, { expires: 1 });
        Cookies.set('avatar', responseData.avatar, { expires: 1 });
        window.location.href='/dashboard';
      }
      else if(responseData.message=="invalid"){
        showErrorMessage();
      }
      setSubmit(false);
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }
  };

  const showErrorMessage = () => {
    toast.error('Invalid Login', {
      position: 'top-center',
    });
  }

  return (
    <MDBRow style={{ margin: 0, width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: 'white' }}>
      <ToastContainer />
      <MDBCol md='12' style={{ ...colStyle, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <MDBCard className='bg-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '500px'}}>
        <MDBCardBody className='p-5 w-100 d-flex flex-column'>
        <div style={{padding: '20px', width: "400px" }}>
          <center>
            {/* <img src="./Assets/logo.png" alt="" style={{ width: '300px' }} /> */}
          <h3 style={{ color: 'black', fontWeight: '300', marginTop: '30px',textAlign:'center',fontWeight:"bold" }}>Admin Login</h3>
          </center>
          <p style={{ color: '#405e77',marginTop:"10px" }}>Login Below</p>
          <form onSubmit={handleSubmit}>
            <div>
              <MDBInput label='Email Address' id='email' name='email' type='text'/>
            </div>
            <div style={{ marginTop: '20px' }}>
              <MDBInput label='cpassword' id='password' name='password' type='password'/>
            </div>
            <MDBBtn style={{ marginTop: '30px', width: '100%', backgroundColor: 'black', color: 'white' }}>
              {submit ? (
                <MDBSpinner></MDBSpinner>
              ) : (
                <span>Login</span>
              )}
            </MDBBtn>
          </form> 
          </div>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}
