import React, {useState,useEffect} from 'react';
import Sidebar from '../../parts/sidebar'
import { MDBTable, MDBTableHead, MDBTableBody, MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBInput,
  MDBBtn
} from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom';

const Usersinfo = () => {
  const [dealers,setDealers]=useState([]);
  const [countries,setCountries]=useState([]);
  const [selectedCountry,setSelectedCountry]=useState("");
  const [showModal, setShowModal] = useState(false);
  const [submit,setSubmit]=useState(false);

  // update user
  const [fname,setFName]=useState("");
  const [lname,setLName]=useState("");
  const [email,setEmail]=useState("");
  const [postCode,setPostCode]=useState("");
  const [city,setCity]=useState("");
  const [address,setAddress]=useState("");
  const [phone,setPhone]=useState("");
  const [group,setGroup]=useState("");
  const [credits,setCredits]=useState();
  const [id,setId]=useState("");

  useEffect(()=>{
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countryList = response.data.map(country => ({
          name: country.name.common,
          code: country.cca2,
        }));
        setCountries(countryList);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
    getDealers();
  },[])

  const getDealers = async (e) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/getDealers`, {
          headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
          },
        });
        const responseData = response.data;
        setDealers(responseData.data);
      } catch (error) {
        console.error('Error:', error.message);
      }
  };

  const deleteDealers = async (id) => {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/Admin/deletedealer?id=${id}`, {
          headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
          },
        });
        const responseData = response.data;
        if(responseData.message=="deleted"){
          getDealers();
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData();
    formData.append('firstname', fname);
    formData.append('lastname', lname);
    formData.append('email', email);
    formData.append('location', selectedCountry);
    formData.append('postcode', postCode);
    formData.append('city', city);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('credits', credits);
    formData.append('type', group);
    formData.append('Id', id);

    try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/updateUser`, formData, {
        headers: {
        'Content-Type': 'application/json',
        "api-key": process.env.REACT_APP_API_KEY,
        },
    });
    const data = response.data;
    if(data.message=="updated"){
        form.reset();
        setShowModal(false);
        getDealers();
        setSubmit(false);
    }
    } catch (error) {
    console.error('Error:', error.message);
    setSubmit(false);
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>Users Info</h3>
        <div style={{overflowX:"auto"}}>
        <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col'>Name</th>
              <th scope='col'>Email</th>
              <th scope='col'>Language</th>
              <th scope='col'>Location</th>
              <th scope='col'>Post Code</th>
              <th scope='col'>City</th>
              <th scope='col'>Address</th>
              <th scope='col'>Phone</th>
              <th scope='col'>Credits</th>
              <th scope='col'>User Group</th>
              <th scope='col'>Dated</th>
              <th scope='col'>Update</th>
              <th scope='col'>Delete</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {dealers.map((item,index)=>(
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='ms-3'>
                        <Link to={`/user-details/${item.Id}`}><p className='fw-bold mb-1'>{item.firstname + ' '+ item.lastname}</p></Link>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className='fw-normal mb-1'>{item.email}</p>
                  </td>
                  <td>
                    <p className='fw-normal mb-1'>{item.language}</p>
                  </td>
                  <td>
                    <p className='fw-normal mb-1'>{item.location}</p>
                  </td>
                  <td>
                    <p className='fw-normal mb-1'>{item.postcode}</p>
                  </td>
                  <td>
                    <p className='fw-normal mb-1'>{item.city}</p>
                  </td>
                  <td>
                    <p className='fw-normal mb-1'>{item.address}</p>
                  </td>
                  <td>
                    <p className='fw-normal mb-1'>{item.phone}</p>
                  </td>
                  <td>
                    <p className='fw-normal mb-1'>{item.credits}</p>
                  </td>
                  <td>
                    <p className='fw-normal mb-1'>{item.type}</p>
                  </td>
                  <td>
                    <p className='fw-normal mb-1'>{new Date(item.createdAt).toLocaleString()}</p>
                  </td>
                  <td>
                  <i className='fa fa-edit' style={{cursor:"pointer"}} onClick={()=>{
                    setFName(item.firstname);
                    setLName(item.lastname);
                    setEmail(item.email);
                    setPostCode(item.postcode);
                    setCity(item.city);
                    setAddress(item.address);
                    setPhone(item.phone);
                    setCredits(item.credits);
                    setSelectedCountry(item.location);
                    setGroup(item.type);
                    setId(item.Id);
                    setShowModal(true);
                  }}></i>
                  </td>
                  <td>
                    <i className='fa fa-trash' style={{cursor:"pointer"}} onClick={()=>{
                      if(window.confirm('Are you sure you want to delete?')){
                        deleteDealers(item.Id);
                      }
                    }}></i>
                  </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
        </div>
      </div>

      <MDBModal show={showModal} onHide={() => setShowModal(false)} tabIndex='-1'>
        <MDBModalDialog centered size="md">
            <MDBModalContent style={{ color: 'black' }}>
            <MDBModalBody>
            <center><h3 style={{color:"black",fontFamily:"bahnschrift",marginTop:"15px"}}>Update User</h3></center>
                
            <center>
                <div>
                    <form onSubmit={handleSubmit}>
                      <MDBInput label='First Name' id='fname' type='text' value={fname} onChange={(e)=>{setFName(e.target.value)}}/>
                      <MDBInput label='Last Name' id='lname' type='text' style={{marginTop:"5px"}} value={lname} onChange={(e)=>{setLName(e.target.value)}}/>
                      <MDBInput label='Email' id='email' type='text' style={{marginTop:"5px"}} value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                      <MDBInput label='Post Code' id='postcode' type='text' style={{marginTop:"5px"}} value={postCode} onChange={(e)=>{setPostCode(e.target.value)}}/>
                      <MDBInput label='City' id='city' type='text' style={{marginTop:"5px"}} value={city} onChange={(e)=>{setCity(e.target.value)}}/>
                      <MDBInput label='Address' id='address' type='text' style={{marginTop:"5px"}} value={address} onChange={(e)=>{setAddress(e.target.value)}}/>
                      <MDBInput label='Phone' id='phone' type='text' style={{marginTop:"5px"}} value={phone} onChange={(e)=>{setPhone(e.target.value)}}/>
                      <MDBInput label='Credits' id='credits' type='number' style={{marginTop:"5px"}} value={credits} onChange={(e)=>{setCredits(e.target.value)}}/>
                      <select
                        className="form-control"
                        style={{ marginTop: '5px',color:"black" }}
                        value={group}
                        onChange={(e)=>{setGroup(e.target.value)}}
                        name='group'
                        required
                        >
                          <option value="">Select User Group</option>
                          <option value="Normal">Normal</option>
                          <option value="Premium">Premium</option>
                          <option value="Custom">Custom</option>
                      </select>
                      <select
                        className="form-control"
                        style={{ marginTop: '5px',color:"black" }}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        name='country'
                        required
                        >
                          <option value="">Select Country</option>
                        {countries.map(country => (
                          <option key={country.code} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                      <MDBBtn style={{marginTop:"8px",width:"100%",backgroundColor:"black",color:"white"}}>
                        {submit?(
                          <MDBSpinner></MDBSpinner>
                        ):(
                          <span>Update</span>
                        )}
                      </MDBBtn>
                    </form>
                </div>
            </center>
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>
    </div>
  );
};

export default Usersinfo;
