import React, { useState, useEffect } from 'react';
import Sidebar from '../../parts/sidebar';
import { MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import { List, ListItem, ListItemText, Typography, Paper } from '@mui/material'
import 'react-chat-widget/lib/styles.css';
import axios from 'axios';
import Cookies from 'js-cookie';

const Chat = () => {
  const [contact, setContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);

  const [selected, setSelected] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [ticket, setTicket] = useState("");

  useEffect(() => {
    getContacts();
    // Fetch messages initially
    getMessages(selectedId, ticket);

    // Setup interval to fetch messages every 10 seconds (adjust the interval as needed)
    const intervalId = setInterval(() => {
      getMessages(selectedId, ticket);
    }, 10000);

    // Cleanup interval on component unmount
    return () => {
      console.log('Cleaning up interval for fetching messages');
      clearInterval(intervalId);
    };
  }, [selectedId, ticket]);

  async function getContacts() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Dealer/getMessages`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        }
      );

      const data = await response.json();
      const uniqueKeys = ['Id', 'ticketNo'];
      const uniqueIdSet = new Set();

      const uniqueIdData = data.data.filter(item => {
        const compositeIdValue = uniqueKeys.map(key => item[key]).join('-');
        if (!uniqueIdSet.has(compositeIdValue)) {
          uniqueIdSet.add(compositeIdValue);
          return true;
        }
        return false;
      });
      setContacts(uniqueIdData);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function getMessages(selectedId, ticket) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Dealer/getMessages`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        }
      );

      const data = await response.json();
      setMessages(data.data.filter((item) => (item.senderId == parseInt(selectedId) || item.recieverId == parseInt(selectedId)) && (item.ticketNo == parseInt(ticket))));
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const messageInput = document.getElementById('message');
    const messageValue = messageInput.value.trim();

    if (messageValue !== '') {
      const formData = new FormData();
      formData.append('message', messageValue);
      formData.append('ticketNo', ticket);
      formData.append('file', file);
      formData.append('recieverId', selectedId);
      formData.append('senderId', Cookies.get("adminId"));
      formData.append('name', Cookies.get("adminName"));

      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Dealer/chat`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        });

        const data = response.data;
        if (data.message === 'added') {
          messageInput.value = '';
          setFile(null);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <MDBRow style={{ margin: "20px" }}>
          <MDBCol md={6}>
            <h3 style={{ color: "black", marginLeft: "2%", fontFamily: "bahnschrift" }}>Chat</h3>
            <Paper elevation={3} style={{ margin: '20px', padding: '20px' }}>
              <Typography variant="h5" gutterBottom>
                Contact List
              </Typography>
              <List>
                {contact.map((contact) => (
                  <ListItem key={contact.Id} divider style={{ cursor: 'pointer' }} onClick={() => {
                    setSelected(contact.firstname);
                    setTicket(contact.ticketNo);
                    setSelectedId(contact.senderId);
                  }}>
                    <ListItemText primary={contact.firstname + ` (Ticket # ${contact.ticketNo})`} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </MDBCol>
          <MDBCol md={6}>
            <Paper elevation={3} style={{ margin: '20px', padding: '20px', marginTop: "52px" }}>
              <Typography variant="h5" gutterBottom>
                {selected}
              </Typography>
              <div style={{ backgroundColor: '#D3D3D3', padding: '10px', color: 'white', borderRadius: '7px', height: '250px', overflowY: 'auto' }}>
                {messages.map((message, index) => (
                  <div key={index} style={{ padding: '10px', color: 'black', borderRadius: '7px', marginTop: '-10px' }}>
                    <p style={{ fontWeight: 'bold' }}>{message.senderId == 1 ? 'ME' : message.firstname}</p>
                    <p style={{ marginTop: '-10px' }}>{message.message}</p>
                    {message.file && (
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: "-20px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ marginRight: '5px' }}>
                          <rect x="2" y="2" width="20" height="20" rx="2" ry="2" />
                          <line x1="16" y1="8" x2="16" y2="16" />
                          <line x1="8" y1="16" x2="16" y2="16" />
                        </svg>
                        <p style={{ marginLeft: '5px', marginTop: "15px" }}><a href={`http://localhost:4000/files/${message.file}`}>{message.file}</a></p>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <form onSubmit={handleSubmit}>
                <MDBInput label="Your Message" id="message" type="text" style={{ marginTop: '7px', color: 'black' }} />
                <MDBInput id="filen" type="file" style={{ marginTop: '7px', color: 'black' }} onChange={(event) => setFile(event.target.files[0])} />
                <MDBRow style={{ marginTop: '10px' }}>
                  <MDBCol md={12}>
                    <MDBBtn style={{ backgroundColor: '#35cce6', width: '100%', marginTop: '5px' }} type="submit">Send Message</MDBBtn>
                  </MDBCol>
                </MDBRow>
              </form>
            </Paper>
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  );
};

export default Chat;
