import React, {useEffect, useState,useRef} from 'react';
import { MDBInput,MDBRow,MDBCol, MDBSpinner } from 'mdb-react-ui-kit';
import Sidebar from '../../parts/sidebar';
import { MDBTable, MDBTableHead, MDBTableBody,MDBCheckbox  } from 'mdb-react-ui-kit';
import axios from 'axios';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalBody,
    MDBBtn
  } from 'mdb-react-ui-kit';

const ECU = () => {
    const [submit,setSubmit]=useState(false);
    const [ori,setOri]=useState(null);
    const [mod,setMod]=useState(null);
    const [cars,setCars]=useState([]);
    const [backcontroller,setBackController]=useState([]);
    const [controllers,setControllers]=useState([]);
    const [carsubmit,setCarsubmit]=useState(false);
    const [controllersubmit,setControllerSubmit]=useState(false);
    const [ecuController,setEcucontroller]=useState([]);
    const [Look,setLook]=useState(false);
    const [Look1,setLook1]=useState(false);
    const [Look2,setLook2]=useState(false);
    const [showModal, setShowModal] = useState(false);
    const [controllerModal, setControllerModel] = useState(false);

    const [selectCar,setSelectedCar]=useState("");
    const fileInputRef = useRef(null);
    const fileInputRefMod = useRef(null);

    const [updateMake,setUpdatemake]=useState("");
    const [updateMakeId,setUpdatemakeId]=useState("");
    const [updateControllerId,setUpdateControllerId]=useState("");
    const [updateMakeSubmit,setUpdateMakeSubmit]=useState(false);
    const [updateControllerSubmit,setUpdateControllerSubmit]=useState(false);
    const [updateController,setUpdateController]=useState("");
    const [updatecontroMake,setupdatecontroMake]=useState("");

    // Add Makes
    const [newMake,setNewMake]=useState("");

    // Add Controller
    const [contrMake,setContMake]=useState("");
    const [newControllerAdd,setNewControllerAdd]=useState("");

    useEffect(()=>{
        document.body.style.overflowX="hidden";
        getDetails();
    },[])

    const getDetails = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getCars`, {
            headers: {
              'Content-Type': 'application/json',
              "api-key": process.env.REACT_APP_API_KEY,
            },
          });
          const responses = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getControllers`, {
            headers: {
              'Content-Type': 'application/json',
              "api-key": process.env.REACT_APP_API_KEY,
            },
          });
          const responseData = response.data;
          const responseDatas = responses.data;

          const sortedCars = responseData.data.slice().sort((a, b) => {
            const nameA = a.carname.toLowerCase();
            const nameB = b.carname.toLowerCase();
          
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          setCars(sortedCars);
          const sortedBackController = responseDatas.data.slice().sort((a, b) => {
            const nameA = a.controllername.toLowerCase();
            const nameB = b.controllername.toLowerCase();
          
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          
          setBackController(sortedBackController);
        } catch (error) {
          console.error('Error:', error.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmit(true);
        const form = e.target;
        const formData = new FormData();
        const controller = document.getElementById("controller").value;
        formData.append('car', selectCar);
        formData.append('controller', controller);
        formData.append('ori', ori);
        formData.append('mod', mod);
        formData.append('credits',document.getElementById("credits").value);
    
        try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/createScripts?controller=${controller}&car=${selectCar}`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            "api-key": process.env.REACT_APP_API_KEY,
            },
        });
        const data = response.data;
        if(data.message=="added"){
            if(Look==false){
                form.reset();
            }
            if(Look2==false){
                fileInputRefMod.current.value = null;
            }
            if(Look1==false){
                fileInputRef.current.value = null;
            }
            setSubmit(false);
        }
        } catch (error) {
        console.error('Error:', error.message);
        setSubmit(false);
        }
    };

    async function handleCars(){
        if(newMake==""){
            alert("Enter Car Name");
        }
        else{
            setCarsubmit(true);
            const newmakedata={
                car:newMake,
            }
        
            try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/addmake`, newmakedata, {
                headers: {
                'Content-Type': 'application/json',
                "api-key": process.env.REACT_APP_API_KEY,
                },
            });
            const data = response.data;
            if(data.message=="added"){
                getDetails();
                setCarsubmit(false);
            }
            } catch (error) {
            console.error('Error:', error.message);
            setCarsubmit(false);
            }
        }
    }

    async function handleCarsUpdate(event){
        event.preventDefault();
        if(updateMake==""){
            alert("Enter Car Name");
        }
        else{
            setUpdateMakeSubmit(true);
            const updatemake={
                car:updateMake,
                id:updateMakeId,
            }
        
            try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/updateMake`, updatemake, {
                headers: {
                'Content-Type': 'application/json',
                "api-key": process.env.REACT_APP_API_KEY,
                },
            });
            const data = response.data;
            if(data.message=="updated"){
                getDetails();
                setShowModal(false);
                setUpdateMakeSubmit(false);
            }
            } catch (error) {
            console.error('Error:', error.message);
            setUpdateMakeSubmit(false);
            }
        }
    }

    async function handleController(){
        if(newControllerAdd=="" || contrMake==""){
            alert("Give complete details");
        }
        else{
            setControllerSubmit(true);
            const newcontrollerdata={
                make:contrMake,
                controller:newControllerAdd,
            }
        
            try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/addController`, newcontrollerdata, {
                headers: {
                'Content-Type': 'application/json',
                "api-key": process.env.REACT_APP_API_KEY,
                },
            });
            const data = response.data;
            if(data.message=="added"){
                getDetails();
                setControllerSubmit(false);
            }
            } catch (error) {
                console.error('Error:', error.message);
                setControllerSubmit(false);
            }
        }
    }

    async function handleCarsDelete(id){
        try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/Admin/deletemake?id=${id}`, {
            headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
            },
        });
        const data = response.data;
        if(data.message=="deleted"){
            getDetails();
        }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    async function handleControllerDelete(id){
        try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/Admin/deleteController?id=${id}`, {
            headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
            },
        });
        const data = response.data;
        if(data.message=="deleted"){
            window.location.href="/ecu";
        }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }


  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>ECU</h3>
            <MDBRow style={{margin:"30px"}}>
                <MDBCol md={4}>
                    <form onSubmit={handleSubmit}>
                        <div class="container">
                            <label for="fruitSelect" class="form-label">Car (or Type)</label>
                            <select
                                className="form-select"
                                id="car"
                                name="solution"
                                onChange={(e) => {
                                    const selectedCarId = e.target.value;
                                    const selectedText = e.target.options[e.target.selectedIndex].text;
                                    setSelectedCar(selectedText);
                                    setEcucontroller(backcontroller.filter((item) => item.makeId == selectedCarId));
                                }}
                                >
                                <option value="">Select Car</option>
                                {cars.map((item, index) => (
                                    <option key={index} value={item.Id}>
                                    {item.carname}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div class="container">
                            <label for="fruitSelect" class="form-label">Controller (or Type)</label>
                            <select class="form-select" id="controller" name="controller">
                            <option value="">Select Controller</option>
                            {ecuController.map((item,index)=>(
                                <option value={item.controllername}>{item.controllername}</option>
                            ))}
                            </select>
                        </div>
                        <div style={{marginTop:"7px",marginLeft:"11px"}}>
                            <label htmlFor="">Credits</label>
                            <MDBInput label='Credits' id='credits' type='number' defaultValue={0}/>
                        </div>
                        <div style={{marginTop:"7px",marginLeft:"11px"}}>
                            <label htmlFor="">Original File</label>
                            <MDBInput
                                id='ori'
                                name='ori'
                                type='file'
                                required
                                onChange={(event) => setOri(event.target.files[0])}
                                ref={fileInputRef}
                            />
                        </div>
                        <div style={{marginTop:"7px",marginLeft:"11px"}}>
                            <label htmlFor="">Mod File</label>
                            <MDBInput
                                id='mod'
                                name='mod'
                                type='file'
                                required
                                onChange={(event) => setMod(event.target.files[0])}
                                ref={fileInputRefMod}
                            />
                        </div>
                        <div style={{marginLeft:"12px",marginTop:"8px",display:"flex"}}>
                            <MDBCheckbox name='flexCheck' id='flexCheckChecked' label='Lock' value={Look} onChange={()=>{
                                setLook(!Look);
                            }}/>
                            <MDBCheckbox name='flexCheck' id='flexCheckChecked' style={{marginLeft:"5px"}} label='Lock File 1' value={Look1} onChange={()=>{
                                setLook1(!Look1);
                            }}/>
                            <MDBCheckbox name='flexCheck' id='flexCheckChecked' style={{marginLeft:"5px"}} label='Lock File 2' value={Look2} onChange={()=>{
                                setLook2(!Look2);
                            }}/>
                        </div>
                        <button type="submit" class="btn btn-primary" data-mdb-ripple-init style={{margin:"15px",color:"white",backgroundColor:"black"}}>
                            {submit?(
                                <MDBSpinner></MDBSpinner>
                            ):(
                                <span>Submit</span>
                            )}
                        </button>
                    </form>
                </MDBCol>
                <MDBCol md={4}>
                    <p>Add New Car</p>
                    <div style={{marginTop:"-7px"}}>
                        <MDBInput label='Car Name' id='newmake' type='text' value={newMake} onChange={(e)=>{setNewMake(e.target.value)}}/>
                    </div>
                    <button onClick={handleCars} class="btn btn-primary" data-mdb-ripple-init style={{marginTop:"5px", color:"white",backgroundColor:"black"}}>
                    {carsubmit?(
                        <MDBSpinner></MDBSpinner>
                    ):(
                        <span>Submit</span>
                    )}
                    </button>
                    <div style={{height:"300px",overflowY:"auto",overflowX:"auto"}}>
                        <MDBTable style={{marginTop:"50x"}}>
                            <MDBTableHead>
                                <tr>
                                <th scope='col'>Make</th>
                                <th scope='col'>Update</th>
                                <th scope='col'>Delete</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                            {cars.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ cursor: "pointer" }} onClick={() => {
                                        setupdatecontroMake(item.carname);
                                        setControllers(backcontroller.filter((controller)=>controller.makeId==item.Id))
                                    }}>{item.carname}</td>
                                    <td>
                                        <i className='fa fa-edit' style={{cursor:"pointer"}} onClick={()=>{
                                            setShowModal(true);
                                            setUpdatemake(item.carname);
                                            setUpdatemakeId(item.Id);
                                        }}></i>
                                    </td>
                                    <td>
                                    <i className='fa fa-trash' style={{ cursor: "pointer" }} onClick={() => {
                                        if (window.confirm("Are you sure you want to delete?")) {
                                        handleCarsDelete(item.Id);
                                        }
                                    }}></i>
                                    </td>
                                </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </MDBCol>
                <MDBCol md={4}>
                    <p>Add New Controller</p>
                    <select class="form-select" id="car" name="solution" value={contrMake} onChange={(e)=>{setContMake(e.target.value)}} style={{marginTop:"-7px"}}>
                        <option value="">Select Car</option>
                        {cars.map((item,index)=>(
                            <option value={item.carname}>{item.carname}</option>
                        ))}
                    </select>
                    <div style={{marginTop:"5px"}}>
                        <MDBInput label='Controller' id='newcontroller' type='text' value={newControllerAdd} onChange={(e)=>{setNewControllerAdd(e.target.value)}}/>
                    </div>
                    <button onClick={handleController} class="btn btn-primary" data-mdb-ripple-init style={{marginTop:"5px", color:"white",backgroundColor:"black"}}>
                    {controllersubmit?(
                        <MDBSpinner></MDBSpinner>
                    ):(
                        <span>Submit</span>
                    )}
                    </button>
                    <div style={{ height: "300px", overflowY: "auto", overflowX: "auto", maxWidth: "100%" }}>
                        <MDBTable>
                            <MDBTableHead>
                                <tr>
                                    <th scope='col'>Controller</th>
                                    <th scope='col'>Update</th>
                                    <th scope='col'>Delete</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {controllers.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.controllername}</td>
                                        <td><i className='fa fa-edit' style={{cursor:"pointer"}} onClick={()=>{
                                            setupdatecontroMake(item.carname);
                                            setUpdateController(item.controllername);
                                            setControllerModel(true);
                                        }}></i></td>
                                        <td>
                                            <i
                                                className='fa fa-trash'
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    if (window.confirm("Are you sure you want to delete?")) {
                                                        handleControllerDelete(item.Id);
                                                    }
                                                }}
                                            ></i>
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </MDBCol>
            </MDBRow>
      </div>

      <MDBModal show={showModal} onHide={() => setShowModal(false)} centered>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalBody>
            <center><h3 style={{color:"black",fontFamily:"bahnschrift",marginTop:"15px"}}>Update Make</h3></center>
                <center>
                    <div>
                    <form onSubmit={handleCarsUpdate}>
                      <MDBInput
                        type='text'
                        label="Make"
                        id="make"
                        name='make'
                        value={updateMake}
                        onChange={(e) => setUpdatemake(e.target.value)}
                      />
                      <MDBBtn
                        type='submit'
                        style={{ marginTop: '8px', width: '100%', color: 'white', backgroundColor: 'black' }}
                      >
                        {updateMakeSubmit ? <MDBSpinner></MDBSpinner> : <span>Update</span>}
                      </MDBBtn>
                    </form>
                    </div>
                </center>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={controllerModal} onHide={() => setControllerModel(false)} centered>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalBody>
            <center><h3 style={{color:"black",fontFamily:"bahnschrift",marginTop:"15px"}}>Update Controller</h3></center>
                <center>
                    <div>
                    <form onSubmit={handleCarsUpdate}>
                    <select class="form-select" id="car" name="solution" value={updatecontroMake} onChange={(e)=>{setupdatecontroMake(e.target.value)}} style={{marginTop:"-7px"}}>
                        <option value="">Select Car</option>
                        {cars.map((item,index)=>(
                            <option value={item.carname}>{item.carname}</option>
                        ))}
                    </select>
                    <div style={{marginTop:"5px"}}>
                        <MDBInput label='Controller' id='newcontroller' type='text' value={updateController} onChange={(e)=>{setUpdateController(e.target.value)}}/>
                    </div>
                      <MDBBtn
                        type='submit'
                        style={{ marginTop: '8px', width: '100%', color: 'white', backgroundColor: 'black' }}
                      >
                        {updateControllerSubmit ? <MDBSpinner></MDBSpinner> : <span>Update</span>}
                      </MDBBtn>
                    </form>
                    </div>
                </center>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default ECU;
