import React, {useState} from 'react';
import Sidebar from '../../parts/sidebar'
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBBtn
} from 'mdb-react-ui-kit';
import axios from 'axios';

const UserDetails = () => {
  const [subscriptions,setSubscriptions]=useState([]);
  const [history,setHistory]=useState([]);
  const { id } = useParams();

  useEffect(()=>{
    getSubscriptions();
    getHistory();
  },[])

  const getSubscriptions = async (e) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getSubscriptions`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setSubscriptions(responseData.data.filter((item)=>item.userId==id));
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const getHistory = async (e) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/gethistory`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setHistory(responseData.data.filter((item)=>item.userId==id));
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
      <MDBRow style={{margin:"30px"}}>
      <MDBCol sm='6'>
        {subscriptions.map((item,index)=>(
            <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Subscription</MDBCardTitle>
              <MDBCardText>
                <p>Starting Date: {new Date(item.dated).toLocaleString()}</p>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        ))}
      </MDBCol>
      <MDBCol sm='6'>
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>User History</MDBCardTitle>
            <MDBCardText>
            <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col'>Car Make</th>
              <th scope='col'>Controller</th>
              <th scope='col'>Solution</th>
              <th scope='col'>Dated</th>
            </tr>
          </MDBTableHead>
            <MDBTableBody>
               {history.map((item,index)=>(
                <tr>
                  <td>
                      <p className='fw-normal mb-1'>{item.make}</p>
                  </td>
                  <td>
                      <p className='fw-normal mb-1'>{item.controller}</p>
                  </td>
                  <td>
                      <p className='fw-normal mb-1'><a href={`${process.env.REACT_APP_BASE_URL}/solutions/${item.solution}`}>Solution</a></p>
                  </td>
                  <td>
                      <p className='text-muted mb-0'>{new Date(item.dated).toLocaleString()}</p>
                  </td>
                </tr>
               ))}
            </MDBTableBody>
            </MDBTable>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
      </div>
    </div>
  );
};

export default UserDetails;
