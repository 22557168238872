import React, {useState} from 'react';
import Sidebar from '../../parts/sidebar'
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { useEffect } from 'react';
import axios from 'axios';

const Subscriptions = () => {
  const [subscriptions,setSubscriptions]=useState([]);

  useEffect(()=>{
    getSubscriptions();
  },[])

  const getSubscriptions = async (e) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getSubscriptions`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setSubscriptions(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  function addOneYearAndFormatDate(dateString) {
    const originalDate = new Date(dateString);
    const newDate = new Date(originalDate);
    newDate.setFullYear(originalDate.getFullYear() + 1);
  
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(newDate);
  
    return formattedDate;
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>Subscriptions</h3>
        <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col'>User Name</th>
              <th scope='col'>Email</th>
              <th scope='col'>Subscription</th>
              <th scope='col'>Start Date</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {subscriptions.map((item,index)=>(
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='ms-3'>
                      <p className='fw-bold mb-1'>{item.firstname}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className='fw-normal mb-1'>{item.email}</p>
                </td>
                <td>
                  <p className='text-muted mb-0'>Annual</p>
                </td>
                <td>
                  <p className='text-muted mb-0'>{new Date(item.dated).toLocaleString()}</p>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
    </div>
  );
};

export default Subscriptions;
