import React, {useEffect,useRef, useState} from 'react';
import Sidebar from '../parts/sidebar'
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdb-react-ui-kit';
import {Form,Button} from 'react-bootstrap';
import { MDBTable, MDBTableHead, MDBTableBody,MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';
import Chart from 'chart.js/auto';
import Cookies from 'js-cookie';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
    // Refs for chart canvases
    const chartRef = useRef(null);
    const chartRef1 = useRef(null);
    const [history,setHistory]=useState([]);
    const [name,setName]=useState("");
    const [email,setEmail]=useState("");
    const [file,setFile]=useState(null);
    const [updatesubmit,setUpdatesubmit]=useState(false);
    const [showModal,setShowModal]=useState(false);
    const [admins,setAdmins]=useState([]);
    const [scripts,setScripts]=useState([]);
  
    // State for dealer data
    const [dealers, setDealers] = useState([]);
    const [userHistory,setUserHistory]=useState([]);
  
    // State for chart data
    const [chartData, setChartData] = useState({
      labels: ['January', 'February', 'March', 'April', 'May'],
      values: [10, 15, 7, 22, 18],
    });
  
    // Fetch dealer data on component mount
    useEffect(() => {
      setName(Cookies.get("adminName"));
      setEmail(Cookies.get("adminEmail"));
      getAdmin();
      getDealers();
      getHistory();
      getDetails();
    }, []);

    const getDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getScripts`, {
          headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
          },
        });
        const responseData = response.data;
        setScripts(responseData.data);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    const getHistory = async (e) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/gethistory`, {
          headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
          },
        });
        const responseData = response.data;
        setHistory(responseData.data);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    const showUpdateMessage = () => {
      toast.success('Admin Updated', {
        position: 'top-center',
      });
    }

    const getAdmin = async (e) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getadmins`, {
          headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
          },
        });
        const responseData = response.data;
        setAdmins(responseData.data.filter((item)=>item.Id==Cookies.get("adminId")));
      } catch (error) {
        console.error('Error:', error.message);
      }
  };
  
    // Effect to render charts when chartData changes
    useEffect(() => {
      if (chartRef.current && chartData.labels && chartData.values) {
        const ctx = chartRef.current.getContext('2d');
  
        new Chart(ctx, {
          type: 'line',
          data: {
            labels: chartData.labels,
            datasets: [
              {
                label: 'Statistics Data',
                data: chartData.values,
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                fill: false,
              },
            ],
          },
          options: {
            scales: {
              x: {
                type: 'category',
              },
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
      if (chartRef1.current && chartData.labels && chartData.values) {
        const ctx = chartRef1.current.getContext('2d');
  
        new Chart(ctx, {
          type: 'line',
          data: {
            labels: chartData.labels,
            datasets: [
              {
                label: 'Statistics Data',
                data: chartData.values,
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                fill: false,
              },
            ],
          },
          options: {
            scales: {
              x: {
                type: 'category',
              },
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
    }, [chartData]);

    const handleUpdate = async (e) => {
      e.preventDefault();
      setUpdatesubmit(true);
      const form = e.target;
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('avatar', file);
      formData.append('id', Cookies.get("adminId"));
  
      try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/updateadmin`, formData, {
          headers: {
          'Content-Type': 'multipart/form-data',
          "api-key": process.env.REACT_APP_API_KEY,
          },
      });
      const data = response.data;
      if(data.message=="update"){
          form.reset();
          setUpdatesubmit(false);
          setShowModal(false);
          getAdmin();
          showUpdateMessage();
      }
      } catch (error) {
      console.error('Error:', error.message);
      setUpdatesubmit(false);
      }
    };
  
    // Function to fetch dealer data
    const getDealers = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/getDealers`, {
          headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
          },
        });
        const responseData = response.data;
        setDealers(responseData.data);
      } catch (error) {
        console.error('Error fetching dealers:', error.message);
      }
    };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ToastContainer />
      <Sidebar />
      <div className='main-content'>
        <MDBRow className='row-cols-1 row-cols-md-4 g-4' style={{margin:'30px'}}>
          <MDBCol>
            <MDBCard className='h-100'>
              <MDBCardBody>
                <MDBCardText>
                    {admins.map((item,index)=>(
                    <div style={{display:'flex',marginTop:"16px"}}>
                      <img src={`${process.env.REACT_APP_BASE_URL}/files/${item.image}`} alt="" style={{width:'40px',height:"40px",borderRadius:"50%"}}/>
                        <div style={{paddingLeft:"10px",lineHeight:"5px",marginTop:"10px"}}>
                          <p style={{fontWeight:"bold"}}>{item.name}<i className="fa fa-edit" onClick={()=>{setShowModal(true)}} style={{ cursor: 'pointer',marginLeft:"15px" }}></i></p>
                          <p style={{fontSize:"12px"}}>{item.email}</p>
                        </div>
                    </div>
                    ))}
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard className='h-100'>
              <MDBCardBody>
                <MDBCardText>
                  <p style={{fontWeight:"bold"}}>Payments</p>
                  <center><img src="../Assets/stripe.png" alt="" style={{width:"200px"}}/></center>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard className='h-100'>
              <MDBCardBody>
                <MDBCardText>
                  <div style={{display:"flex",justifyContent:"space-between",marginTop:"15px"}}>
                    <p style={{fontWeight:"bold"}}>Scripts</p>
                    <p>All</p>
                  </div>
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                    <i class="fas fa-file"></i>
                    <p>{scripts.length}</p>
                  </div>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          {/* <MDBCol>
            <MDBCard className='h-100'>
              <MDBCardBody>
                <MDBCardText>
                  <p>Languages</p>
                  <center><img src="https://media.istockphoto.com/id/854115776/photo/flag-of-england.jpg?s=612x612&w=0&k=20&c=EojPOFUwYV_8vhsJ9RR06rmTwOsSpfVqWbbxGiVpxgk=" alt="" style={{width:'100px'}}/></center>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol> */}
      </MDBRow>

        {/* <MDBRow className='row-cols-1 row-cols-md-2 g-4' style={{margin:'30px'}}>
          <MDBCol>
            <MDBCard className='h-100'>
              <MDBCardBody>
                <MDBCardText>
                  <p style={{fontWeight:"bold"}}>Statistics</p>
                  <center><canvas ref={chartRef} width="400" height="200"></canvas></center>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard className='h-100'>
              <MDBCardBody>
                <MDBCardText>
                  <p style={{fontWeight:"bold"}}>Revenue</p>
                  <center><canvas ref={chartRef1} width="400" height="200"></canvas></center>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
      </MDBRow> */}

        <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{margin:'30px'}}>
          <MDBCol md={4}>
            <MDBCard className='h-100'>
              <MDBCardBody>
                <MDBCardText>
                 <p style={{fontWeight:"bold"}}>Users</p>
                 {dealers.map((item,index)=>(
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                      <div style={{display:"flex"}}>
                        <div style={{lineHeight:"5px",marginTop:"11px",marginLeft:"10px",cursor:"pointer"}} onClick={()=>{
                          setUserHistory(history.filter((item)=>item.userId=item.Id));
                        }}>
                          <p style={{fontWeight:"bold"}}>{item.firstname+' '+item.lastname}</p>
                          <p>{item.email}</p>
                        </div>
                      </div>
                    </div>
                 ))}
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md={8}>
            <MDBCard className='h-100'>
              <MDBCardBody>
                <MDBCardText>
                  <p style={{fontWeight:"bold"}}>History</p>
                  <MDBTable>
                    <MDBTableHead>
                      <tr>
                        <th scope='col'>Car Make</th>
                        <th scope='col'>Controller</th>
                        <th scope='col'>Solution</th>
                        <th scope='col'>Dated</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {userHistory.map((item,index)=>(
                        <tr>
                          <td>
                              <p className='fw-normal mb-1'>{item.make}</p>
                          </td>
                          <td>
                              <p className='fw-normal mb-1'>{item.controller}</p>
                          </td>
                          <td>
                              <p className='fw-normal mb-1'><a href={`${process.env.REACT_APP_BASE_URL}/solutions/${item.solution}`}>Solution</a></p>
                          </td>
                          <td>
                              <p className='text-muted mb-0'>{new Date(item.dated).toLocaleString()}</p>
                          </td>
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
      </MDBRow>
    </div>

    <MDBModal show={showModal} onHide={() => setShowModal(false)} tabIndex='-1'>
        <MDBModalDialog centered size="md">
            <MDBModalContent style={{ color: 'black' }}>
            <MDBModalBody>
            <center><h3 style={{color:"black",fontFamily:"bahnschrift",marginTop:"15px"}}>Update Admin</h3></center>
                
            <center>
                <div>
                    <form onSubmit={handleUpdate}>
                        <Form.Group className="mb-3" controlId="formName">
                        <Form.Control
                            placeholder="Admin Name"
                            type="name"
                            name="name"
                            id="name"
                            required
                            value={name}
                            onChange={(e)=>{setName(e.target.value)}}
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formName">

                        <Form.Control
                            placeholder="Enter Email"
                            type="email"
                            name="adminemail"
                            id="email"
                            required
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Control
                            id="avatar"
                            name="avatar"
                            type="file"
                            required
                            onChange={(e) => {setFile(e.target.files[0])}}
                            style={{ backgroundColor: "#F9F9F9" }}
                        />
                        </Form.Group>
                        <Button
                            type="submit"
                            style={{
                                backgroundColor: "black",
                                color:"white",
                                border: "none",
                                fontWeight: "bold",
                                width: "100%", 
                            }}
                            block 
                            >
                            {updatesubmit?(
                                <MDBSpinner></MDBSpinner>
                            ):(
                                <span>Update Admin</span>
                            )}
                        </Button>
                    </form>
                </div>
            </center>
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>
    </div>
  );
};

export default Dashboard;
