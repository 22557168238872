import React, {useState,useEffect} from 'react';
import Sidebar from '../../parts/sidebar';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

const Tickets = () => {
  const [requests, setRequests] = useState([]);

  useEffect(()=>{
    getRequests();
  },[])

  async function getRequests() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Admin/getRequests`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        }
      );

      const data = await response.json();
      setRequests(data.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>Tickets</h3>
         <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col'>Make</th>
              <th scope='col'>ECU/TCU</th>
              <th scope='col'>Dated</th>
              <th scope='col'>Status</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {requests.map((item,index)=>(
              <tr>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='ms-3'>
                    <p className='fw-bold mb-1'>{item.make}</p>
                  </div>
                </div>
              </td>
              <td>
                <p className='fw-normal mb-1'>{item.ecu}</p>
              </td>
              <td>
                <p className='text-muted mb-0'>{new Date(item.dated).toLocaleString()}</p>
              </td>
              <td>
                <p className='text-muted mb-0'>{item.status}</p>
              </td>
            </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
    </div>
  );
};

export default Tickets;
