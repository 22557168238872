import React,{useState} from 'react';
import Sidebar from '../../parts/sidebar';
import { MDBRow,MDBCol,MDBBtn,MDBSpinner } from 'mdb-react-ui-kit';
import axios from 'axios';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

const Status = () => {
  const [status,setStatus]=useState();
  const [submit,setSubmit]=useState(false);

  useEffect(()=>{
    getsupport();
  },[])

  const getsupport = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getsupport?id=${Cookies.get("adminId")}`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data; 
      console.log(responseData.status,typeof(responseData.status));
      setStatus(responseData.status);
    } catch (error) {
      console.error('Error:', error.message);
    } 
  };

  const support = async (status) => {
    setSubmit(true);
    try {
      const data={
        status:status,
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/support?id=${Cookies.get("adminId")}`,data, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      if(responseData.message=="updated"){
        setSubmit(false);
        getsupport();
      }
    } catch (error) {
      console.error('Error:', error.message);
    } 
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"3%",fontFamily:"bahnschrift",marginTop:"3%"}}>Status</h3>
        {status=='1'?(
          <p style={{marginLeft:"3%"}}>You are Online Currently</p>
        ):(
          <p style={{marginLeft:"3%"}}>You are Offline Currently</p>
        )}
        <MDBRow style={{margin:"20px"}}>
            <MDBCol md={8}>
              {status=='1'?(
                <MDBBtn style={{backgroundColor:"green"}} onClick={()=>{
                  support(false);
                }}>
                  {submit?(
                    <MDBSpinner></MDBSpinner>
                  ):(
                    <span>Close Now</span>
                  )}
                </MDBBtn>
              ):(
                <MDBBtn style={{backgroundColor:"red"}} onClick={()=>{
                  support(true);
                }}>{submit?(
                  <MDBSpinner></MDBSpinner>
                ):(
                  <span>Active Now</span>
                )}</MDBBtn>
              )}
            </MDBCol>
        </MDBRow>
        </div>
    </div>
  );
};

export default Status;
