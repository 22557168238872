import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';
import './sidebar.css';
import Cookies from 'js-cookie';

export default function App() {
  const [showShow, setShowShow] = useState(false);

  const toggleShow = () => setShowShow(!showShow);

  return (
    <>
      <MDBCollapse show={showShow} tag="nav" className="d-lg-block sidebar" style={{backgroundColor:"#000000",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
        <div className="position-sticky">
          <MDBListGroup flush className="mx-3 mt-4">
            <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"10px"}}>General</p>
            <MDBRipple rippleTag='span'>
              <MDBListGroupItem tag='a' href='/dashboard' className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
                <MDBIcon fas icon="home me-3" style={{color:"#ABAEB1"}}/>
                Dashboard
              </MDBListGroupItem>
            </MDBRipple>

            <MDBRipple rippleTag='span'>
            <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"10px"}}>Users</p>
              <MDBListGroupItem tag='a' href='/users-info' action className='border-0' aria-current='true' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
              <MDBIcon fas icon="users me-3" style={{color:"#ABAEB1"}}/>
                Manage Users
              </MDBListGroupItem>
              {Cookies.get("adminId")==process.env.REACT_APP_KEY?(
                <MDBListGroupItem tag='a' href='/manage-admins' action className='border-0' aria-current='true' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
                <MDBIcon fas icon="users me-3" style={{color:"#ABAEB1"}}/>
                  Manage Admins
                </MDBListGroupItem>
              ):(
                ""
              )}
            </MDBRipple>

            <MDBRipple rippleTag='span'>
            <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"12px"}}>Functions</p>
              <MDBListGroupItem tag='a' href='/ecu' action className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
              <MDBIcon fas icon="file" className="me-3" style={{ color: "#ABAEB1" }} />
                Add Solutions
              </MDBListGroupItem>
            </MDBRipple>

            <MDBRipple rippleTag='span'>
              <MDBListGroupItem tag='a' href='/solutions' action className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
              <MDBIcon fas icon="file-code me-3" />
                Solutions
              </MDBListGroupItem>
            </MDBRipple>

            <MDBRipple rippleTag='span'>
              <MDBListGroupItem tag='a' href='/scripts' action className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
              <MDBIcon fas icon="file-code me-3" />
                Scripts
              </MDBListGroupItem>
            </MDBRipple>

            <MDBRipple rippleTag='span'>
              <MDBListGroupItem tag='a' href='/pricing' action className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
              <MDBIcon fas icon="dollar-sign me-3" />
              Edit Price
              </MDBListGroupItem>
            </MDBRipple>

            {/* <MDBRipple rippleTag='span'>
            <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"10px"}}>Notifications</p>
              <MDBListGroupItem tag='a' href='/requests' action className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
              <MDBIcon fas icon="comment me-3" />
                Requests
              </MDBListGroupItem>
            </MDBRipple> */}

            {/* <MDBRipple rippleTag='span'>
              <MDBListGroupItem tag='a' href='/chat' action className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
              <MDBIcon fas icon="envelope me-3" />
                Live Chat
              </MDBListGroupItem>
            </MDBRipple> */}

            {/* <MDBRipple rippleTag='span'>
              <MDBListGroupItem tag='a' href='/email' action className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
              <MDBIcon fas icon="envelope me-3" />
                Mail to All Users
              </MDBListGroupItem>
            </MDBRipple> */}

            <MDBRipple rippleTag='span'>
            <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"12px"}}>Status</p>
              <MDBListGroupItem tag='a' href='/timing' action className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
              <MDBIcon far icon="bell" className="me-3" />
                Open Timing
              </MDBListGroupItem>
              <MDBListGroupItem tag='a' href='/status' action className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px"}}>
              <MDBIcon fas icon="bell me-3" />
                Support Status
              </MDBListGroupItem>
              <MDBListGroupItem tag='a' onClick={()=>{
                if(window.confirm('Are you sure you want to logout?')){
                  Cookies.remove("adtoken");
                  Cookies.remove("adminEmail");
                  Cookies.remove("adminId");
                  window.location.href="/";
                }
              }} action className='border-0' style={{backgroundColor:"#000000",color:"#ABAEB1",fontSize:"12px",cursor:"pointer"}}>
              <MDBIcon fas icon="sign-out-alt me-3" />
                Logout
              </MDBListGroupItem>
            </MDBRipple>
          </MDBListGroup>
        </div>
      </MDBCollapse>

      <MDBNavbar expand='lg' style={{backgroundColor:"#fff",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
        <MDBContainer fluid>
          <MDBNavbarNav className="d-flex flex-row align-items-center w-auto">
            <MDBNavbarToggler
              type='button'
              aria-label='Toggle navigation'
              onClick={toggleShow}
            >
              <MDBIcon icon='bars' fas />
            </MDBNavbarToggler>
            <MDBNavbarBrand href='#'>
              <h3 style={{fontWeight:"bold"}}>Admin Portal</h3>
            </MDBNavbarBrand>


          </MDBNavbarNav>
          <MDBNavbarNav className="d-flex flex-row justify-content-end w-auto">
            <MDBNavbarItem className='me-3 me-lg-0 d-flex align-items-center'>
            </MDBNavbarItem>
            <MDBNavbarItem className='me-3 me-lg-0 d-flex align-items-center'>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}