import React, {useEffect, useState} from 'react';
import Sidebar from '../../parts/sidebar';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
  

const Users = () => {
  const [dealers,setDealers]=useState([]);

  useEffect(()=>{
    getDealers();
  },[])

  const getDealers = async (e) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/getDealers`, {
          headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
          },
        });
        const responseData = response.data;
        setDealers(responseData.data);
      } catch (error) {
        console.error('Error:', error.message);
      }
  };

  const UpdateStatus = async (dealerId,status) => {
      const data={
          id:dealerId,
          status:status,
      }
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/updateUserStatus`,data,{
          headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
          },
        });
        const responseData = response.data;
        if(responseData.message=="updated"){
          getDealers();
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />
      <div className='main-content'>
        <h3 style={{color:"black",marginLeft:"2%",fontFamily:"bahnschrift"}}>Manage Users</h3>
        <MDBTable align='middle'>
          <MDBTableHead>
            <tr>
              <th scope='col'>Name</th>
              <th scope='col'>Email</th>
              <th scope='col'>Status</th>
              <th scope='col'>Actions</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {dealers.map((item,index)=>(
                <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='ms-3'>
                      <Link to={`/user-details/${item.Id}`}><p className='fw-bold mb-1'>{item.firstname+' '+item.lastname}</p></Link>
                    </div>
                  </div>
                </td>
                <td>
                  <p className='fw-normal mb-1'>{item.email}</p>
                </td>
                <td>
                  {item.Active==1?(
                    <MDBBadge color='success' pill>
                    Active
                    </MDBBadge>
                  ):(
                  <MDBBadge color='danger' pill>
                    Inactive
                  </MDBBadge>
                  )}
                </td>
                <td>
                  {item.Active==1?(
                    <MDBBtn color='link' rounded size='sm' onClick={()=>{
                      if(window.confirm('Are you sure you want to Disable?')){
                        UpdateStatus(item.Id,false)
                      }
                    }}>
                      Disable
                    </MDBBtn>
                  ):(
                  <MDBBtn color='link' rounded size='sm' onClick={()=>{
                    if(window.confirm('Are you sure you want to Enable?')){
                      UpdateStatus(item.Id,true)
                    }
                  }}>
                    Enable
                  </MDBBtn>
                  )}
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </div>
    </div>
  );
};

export default Users;
